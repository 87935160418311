@media (min-width: 576px) {
	.h-sm-100 {
		height: 100%;
	}
}

:root {
  --main-accent-color: #6ea8fe;
}

html { height: 100%; }

body {
	height: 100%;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root { height: 100%; }

.keep-it-100 { height: 100%; }

.login-reg-box {
	margin-top: 2em;
	padding: 2em;
	border: 3px solid black;
	border-radius: 10px;
}

.container-fluid {
	height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.actionIcon {
	margin-right: .75em;
}

.unlink {
	color: inherit; /* blue colors for links too */
  text-decoration: inherit; /* no underline */
}

.bold {
	font-weight: bold !important;
}

.tabulator {
	font-size: 110%;
	font-weight: bold;
	/*margin-right: 1em;
	margin-left: 1em;
	padding-top: 1em;*/
}

.tabulator-header {
	display: none;
}


.dataTable {
	width: 100%;
	font-size: 110%;
	font-weight: 500;
	overflow: hidden;
}

.dataTable .dtRow {
	padding: 0.5em;
	overflow: hidden;

	border-radius: 15px;
	border: 1px solid var(--main-accent-color);
}

.dataTable div {
}

.tableDomainName { width: 50%; min-width: 50%; float: left; line-height: 2; }
.tableDomainExpire { width: 25%; float: left; line-height: 2; }
.tableDomainCount { width: 5%; float: left; line-height: 2; }
.tableDomainControl { float: right; font-size: 130%; }
.tableDomainControl svg { padding-left: .5em; opacity: 0.6; transition: 0.3s; }
.tableDomainControl svg:hover { opacity: 1 }


.tableEmailName { width: 60%; float: left; line-height: 2; }
/*.tableEmailDomain { width: 35%; float: left; }*/
.tableEmailSize { width: 10%; float: left; line-height: 2; }
.tableEmailControl { float: right; font-size: 130%; }
.tableEmailControl svg { padding-left: .5em; opacity: 0.6; transition: 0.3s; }
.tableEmailControl svg:hover { opacity: 1 }


@media(max-width: 992px) {
    button.full-width {
        width: 100%;  
    }
}


.settingItem {
	border-radius: 15px;
  border: 1px solid var(--main-accent-color);
}

.rotateicon {
	transform: rotate(0.25turn);
	transition-duration: .25s;
}
